
import { defineComponent } from "vue";
import cronstrue from "cronstrue";
import { isValidCron } from "cron-validator";

export default defineComponent({
  name: "ConfigFormComponent",
  props: ["submitText", "item"],
  emits: ["submit"],
  data: () => ({
    nameTextfield: "",
    cronTextfield: "",
  }),
  created() {
    this.nameTextfield = this.item?.name || "";
    this.cronTextfield = this.item?.cron || "";
  },
  methods: {
    submit() {
      this.$emit("submit", {
        ...this.item,
        name: this.nameTextfield,
        cron: this.cronTextfield,
      });
    },
    isCronValid() {
      return isValidCron(this.cronTextfield) || !this.cronTextfield;
    },
    cronToString() {
      try {
        return cronstrue.toString(this.cronTextfield);
      } catch (e) {
        return;
      }
    },
  },
});
