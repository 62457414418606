
import { defineComponent } from "vue";
import logo from "./assets/images/logo.svg";

export default defineComponent({
  name: "App",
  data() {
    return {
      logo,
    };
  },
});
