
import { defineComponent } from "vue";
import { startUploadFlow } from "@/api/upload";
import neonCatGif from "../assets/images/neon-cat.gif";
import neonCatPng from "../assets/images/neon-cat.png";

interface DataProps {
  title: string;
  description: string;
  imageKeywords: string[];
  voice?: string;
  style?: string;
  background?: string;
  textContent: string;
  status?: number;
  neonCatGif: string;
  neonCatPng: string;
}

export default defineComponent({
  name: "UploadComponent",
  props: ["data"],
  data(): DataProps {
    return {
      title: this.data?.title,
      description: this.data?.description,
      imageKeywords: this.data?.imageKeywords,
      voice: this.data?.voice,
      style: this.data?.style,
      background: this.data?.background,
      textContent: this.data?.textContent,
      status: undefined,
      neonCatGif,
      neonCatPng,
    };
  },
  computed: {
    uploaded(): boolean {
      return this.status === 200;
    },
  },
  watch: {
    data(newData: DataProps) {
      this.title = newData.title;
      this.description = newData.description;
      this.imageKeywords = newData.imageKeywords;
      this.voice = newData.voice;
      this.style = newData.style;
      this.background = newData.background;
      this.textContent = newData.textContent;
    },
  },
  methods: {
    async callUploadFlow() {
      try {
        const res = await startUploadFlow({
          title: this.title,
          description: this.description,
          imageKeywords: this.imageKeywords,
          voice: this.voice,
          style: this.style,
          background: this.background,
          textContent: this.textContent,
        });
        this.status = res.status;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
