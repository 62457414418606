
import { defineComponent } from "vue";
import { TopPost } from "../api/reddit";
import SearchComponent from "@/components/SearchComponent.vue";
import SpecifyComponent from "@/components/SpecifyComponent.vue";
import UploadComponent from "@/components/UploadComponent.vue";

interface PostProps {
  title: string;
  description: string;
  imageKeywords: string[];
  voice: string;
  style: string;
  background: string;
  textContent: string;
}

interface DataProps {
  step: number;
  selectedPost?: TopPost;
  uploadContent?: PostProps;
}

export default defineComponent({
  name: "UploadPage",
  components: {
    SearchComponent,
    SpecifyComponent,
    UploadComponent,
  },
  data(): DataProps {
    return {
      step: 1,
      selectedPost: undefined,
      uploadContent: undefined,
    };
  },

  methods: {
    goBack() {
      this.step -= 1;
    },
    step1(post: TopPost) {
      this.selectedPost = post;
      this.step += 1;
    },
    step2(post: PostProps) {
      this.uploadContent = post;
      this.step += 1;
    },
  },
});
