
import { defineComponent } from "vue";

interface DataProps {
  title?: string;
  description?: string;
  imageKeywords?: string[];
  voice?: string;
  voices: string[];
  style?: string;
  styles: string[];
  background?: string;
  backgrounds: string[];
  textContent?: string;
  errorMessage?: string;
  status?: number;
}

export default defineComponent({
  name: "SpecifyComponent",
  props: ["data"],
  data(): DataProps {
    return {
      title: "",
      description: "",
      imageKeywords: [],
      textContent: "",
      voice: undefined,
      voices: ["Matthew"],
      style: undefined,
      styles: [
        "cartoon",
        "comic book",
        "futuristic",
        "graffiti",
        "impressionism",
        "manga",
        "oil painting",
        "pencil sketch",
        "pop art",
        "surrealism",
        "watercolor",
      ],
      background: undefined,
      backgrounds: [
        "abstract",
        "apocalyptic",
        "beach",
        "bright neon lights",
        "city",
        "cyberpunk",
        "desert",
        "dystopia",
        "forest",
        "galaxy",
        "jungle",
        "lake",
        "mountain",
        "ocean",
        "ruins",
        "space",
        "underwater",
        "urban",
        "utopian",
        "volcano",
        "waterfall",
      ],
      errorMessage: "",
      status: undefined,
    };
  },
  updated() {
    this.errorMessage = "";
    this.title = this.data?.title;
    this.description = this.data?.description;
    this.imageKeywords = this.data?.keywords;
    this.textContent = this.data?.selftext;
  },
  methods: {
    submitForm: async function () {
      if (
        this.title === "" ||
        this.description === "" ||
        !this.imageKeywords ||
        this.textContent === ""
      ) {
        this.errorMessage = "Please fill out all fields correctly";
        return;
      }
      this.errorMessage = "";
      const post = {
        title: this.title,
        description: this.description,
        imageKeywords: this.imageKeywords,
        voice: this.voice,
        style: this.style,
        background: this.background,
        textContent: this.textContent,
      };
      this.$emit("submitStep", post);
    },
    goBack: function () {
      this.$emit("back");
    },
  },
});
