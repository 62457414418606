import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0f1f4f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-component" }
const _hoisted_2 = ["src", "title"]
const _hoisted_3 = { class: "video-stats mt-2" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "video-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { class: "mx-auto" }, {
      default: _withCtx(() => [
        _createElementVNode("iframe", {
          width: "280",
          height: "280",
          src: 'https://www.youtube.com/embed/' + _ctx.video.VideoId,
          title: _ctx.video.Title,
          frameborder: "0",
          allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: ""
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rating(_ctx.video), (n) => {
                return (_openBlock(), _createBlock(_component_v_icon, {
                  key: n,
                  size: "small",
                  color: "warning",
                  icon: 'mdi-star'
                }))
              }), 128)),
              (_ctx.rating(_ctx.video) == 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, "No Rating"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_v_card_subtitle, { class: "view-count" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "mr-2",
                  size: "small",
                  icon: 'mdi-eye'
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.video.Group.Community.Statistics.Views), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.video.Title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.video.Group.Description), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}