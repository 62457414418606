import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_text_field, {
      modelValue: _ctx.nameTextfield,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nameTextfield) = $event)),
      label: "Subreddit",
      placeholder: "Write name of subreddit",
      variant: "outlined",
      class: "mb-2",
      rules: [() => !!_ctx.nameTextfield || 'Subreddit is required']
    }, null, 8, ["modelValue", "rules"]),
    _createVNode(_component_v_text_field, {
      modelValue: _ctx.cronTextfield,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cronTextfield) = $event)),
      label: "Periodicity",
      placeholder: "Write your periodicity",
      variant: "outlined",
      class: "mb-2",
      hint: _ctx.cronToString(),
      "persistent-hint": "",
      rules: [
        () => _ctx.isCronValid() || !_ctx.cronTextfield || 'Invalid cron expression',
      ]
    }, null, 8, ["modelValue", "hint", "rules"]),
    _createVNode(_component_v_btn, {
      size: "x-large",
      color: "success",
      class: "mb-4",
      block: "",
      onClick: _ctx.submit,
      disabled: !_ctx.nameTextfield || !_ctx.isCronValid()
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.submitText), 1)
      ]),
      _: 1
    }, 8, ["onClick", "disabled"])
  ]))
}