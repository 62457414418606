
import { defineComponent } from "vue";
import { TopPost } from "../api/reddit";
import { getTopPosts, searchSubreddits } from "../api/reddit";

interface DataProps {
  autocomplete: string;
  items: string[];
  posts: TopPost[];
  selectedId: string;
  openedPanel: number[];
  noResponse: boolean;
  isLoading: boolean;
}

export default defineComponent({
  name: "SearchComponent",
  data: (): DataProps => ({
    autocomplete: "",
    selectedId: "",
    openedPanel: [],
    noResponse: false,
    posts: [],
    items: [],
    isLoading: false,
  }),
  methods: {
    isSelected(id: string) {
      return this.selectedId === id;
    },
    selectPost(id: string) {
      if (this.selectedId === id) {
        this.openedPanel = [];
        this.selectedId = "";
      } else {
        this.selectedId = id;
      }
    },
    submitStep() {
      const post = this.posts.find((post) => this.isSelected(post.data.id));
      this.$emit("submitStep", post?.data);
    },
    async fetchTopPosts() {
      this.noResponse = false;
      this.openedPanel = [];
      this.selectedId = "";

      try {
        let response = await getTopPosts(this.autocomplete);
        this.posts = response.parsedBody || [];
      } catch (error) {
        this.noResponse = true;
        this.posts = [];
      }
    },
  },
  watch: {
    async autocomplete() {
      if (this.isLoading) return;

      this.isLoading = true;
      try {
        const response = await searchSubreddits(this.autocomplete);
        const items = response.parsedBody || [];
        this.items = items.slice(0, 5);
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
  },
});
