import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45378afc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "videos mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoComponent = _resolveComponent("VideoComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (video) => {
        return (_openBlock(), _createBlock(_component_VideoComponent, {
          key: video.VideoId,
          video: video
        }, null, 8, ["video"]))
      }), 128))
    ])
  ]))
}