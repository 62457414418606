
import { defineComponent } from "vue";
import { YoutubeVideoData } from "../api/youtube";

export default defineComponent({
  name: "VideoComponent",
  props: ["video"],
  methods: {
    rating(video: YoutubeVideoData) {
      return Math.round(Number(video.Group.Community.StarRating.Average));
    },
  },
});
