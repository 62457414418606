
import { defineComponent } from "vue";
import VideoComponent from "@/components/VideoComponent.vue";
import { getUploadedYoutubeVideos, YoutubeVideoData } from "../api/youtube";

interface DataProps {
  videos: YoutubeVideoData[];
}

export default defineComponent({
  name: "HomePage",
  components: {
    VideoComponent,
  },
  data(): DataProps {
    return {
      videos: [],
    };
  },
  async created() {
    try {
      const response = await getUploadedYoutubeVideos();
      this.videos = response.parsedBody || [];
    } catch (error) {
      console.error(error);
    }
  },
});
