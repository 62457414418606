
import { defineComponent } from "vue";
import cronstrue from "cronstrue";
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import ConfigFormComponent from "../components/ConfigFormComponent.vue";
import {
  getConfigurations,
  deleteConfigEntry,
  upsertConfig,
} from "../api/config";
import RemoveModal from "../modals/RemoveModal.vue";

interface ItemProps {
  id: string;
  name: string;
  cron: string | undefined;
  createdAt?: string;
}

export default defineComponent({
  name: "ConfigPage",
  components: {
    ConfigFormComponent,
    RemoveModal,
  },
  data() {
    return {
      cronstrue,
      queryClient: useQueryClient(),
      selectedItemId: "",
      removalItemId: "",
      nameTextfield: "",
      cronTextfield: "",
      removeDialog: false,
      newConfigurationOpen: false,
      items: [] as ItemProps[],
    };
  },
  methods: {
    toggleNewConfiguration() {
      this.nameTextfield = "";
      this.cronTextfield = "";
      if (this.newConfigurationOpen) {
        this.newConfigurationOpen = false;
      } else {
        this.selectedItemId = "";
        this.newConfigurationOpen = true;
      }
    },
    toggleConfigure(item: ItemProps) {
      if (this.selectedItemId === item.id) {
        this.selectedItemId = "";
        return;
      }
      if (this.newConfigurationOpen) {
        this.newConfigurationOpen = false;
      }
      this.selectedItemId = item.id;
      this.nameTextfield = item.name;
      this.cronTextfield = item.cron ? item.cron : "";
    },
    openRemoveDialog(item: ItemProps) {
      this.removeDialog = true;
      this.removalItemId = item.id;
    },
    async create(item: ItemProps) {
      try {
        await upsertConfig({
          name: item.name,
          cron: item.cron || undefined,
        });
        this.queryClient.invalidateQueries({ queryKey: ["items"] });
      } catch (error) {
        console.error(error);
      }
      this.newConfigurationOpen = false;
      this.nameTextfield = "";
      this.cronTextfield = "";
    },
    async save(item: ItemProps) {
      try {
        await upsertConfig({
          id: item.id,
          name: item.name,
          cron: item.cron || undefined,
        });
        this.queryClient.invalidateQueries({ queryKey: ["items"] });
      } catch (error) {
        console.error(error);
      }
      this.selectedItemId = "";
      this.nameTextfield = "";
      this.cronTextfield = "";
    },
    async remove() {
      try {
        await deleteConfigEntry(this.removalItemId);
        this.queryClient.invalidateQueries({ queryKey: ["items"] });
      } catch (error) {
        console.error(error);
      }
      this.selectedItemId = "";
      this.removeDialog = false;
    },
    cancel() {
      this.removeDialog = false;
    },
  },
  setup() {
    const query = useQuery({
      queryKey: ["items"],
      queryFn: getConfigurations,
    });
    return {
      query,
    };
  },
});
